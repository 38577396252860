// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Actions
// ----------------------------------------------------------------------------
export const INCREASE = 'INCREASE'
export const DECREASE = 'DECREASE'
export const UPDATE_MEDIA_STATE = 'UPDATE_MEDIA_STATE'
export const UPDATE_SCREEN_SIZE_STATE = 'UPDATE_SCREEN_SIZE_STATE'
export const UPDATE_HEADER_STATE = 'UPDATE_HEADER_STATE'

export const UPDATE_GRID_OVERLAY_VIEW_STATE = 'UPDATE_GRID_OVERLAY_VIEW_STATE'
export const UPDATE_GRID_OVERLAY_STATE = 'UPDATE_GRID_OVERLAY_STATE'

export const UPDATE_DAY_NIGHT_MODE = 'UPDATE_DAY_NIGHT_MODE'
export const UPDATE_COLOR = 'UPDATE_COLOR'

export const UPDATE_AUDIO_PLAYER_CURRENT_URL = 'UPDATE_AUDIO_PLAYER_CURRENT_URL'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------ Action creators
// ----------------------------------------------------------------------------
/** Increase */
export const increase = (initObject) => ({
  type: INCREASE,
  payload: initObject,
})

/** Decrease */
export const decrease = (initObject) => ({
  type: DECREASE,
  payload: initObject,
})

/** updateMediaState */
export const updateMediaState = (initObject) => ({
  type: UPDATE_MEDIA_STATE,
  payload: initObject,
})

/** updateScreenSizeState */
export const updateScreenSizeState = (initObject) => ({
  type: UPDATE_SCREEN_SIZE_STATE,
  payload: initObject,
})

/** updateHeaderState */
export const updateHeaderState = (initObject) => ({
  type: UPDATE_HEADER_STATE,
  payload: initObject,
})

/** updateGridOverlayViewState */
export const updateGridOverlayViewState = (initObject) => ({
  type: UPDATE_GRID_OVERLAY_VIEW_STATE,
  payload: initObject,
})

/** updateGridOverlayState */
export const updateGridOverlayState = (initObject) => ({
  type: UPDATE_GRID_OVERLAY_STATE,
  payload: initObject,
})

/** updateDayNight */
export const updateDayNightMode = (initObject) => ({
  type: UPDATE_DAY_NIGHT_MODE,
  payload: initObject,
})

/** updateColor */
export const updateColor = (initObject) => ({
  type: UPDATE_COLOR,
  payload: initObject,
})

/** updateAudioPlayerCurrentURL */
export const updateAudioPlayerCurrentURL = (initObject) => ({
  type: UPDATE_AUDIO_PLAYER_CURRENT_URL,
  payload: initObject,
})
